<template>
    <div class="container">
        <ReportHeader>
            <div class="choose_date">
                <ShowYear :year="weekYearMonth.year" />
            </div>
            <div class="small_logo">
                <img src="@/assets/img/customer-week-report/cmj.png" alt="">
            </div>
            <div class="choose_month">
                <ShowMonth :months="[weekYearMonth.month]" />
            </div>
            <!-- <HeaderRightLogo /> -->
        </ReportHeader>
        <div class="title_row">
            <PageTitleHome title="新闻版块" />
            <WeekSelect :list="weekList" :model-value="weeklyId" :options="{ label: 'weeklyTitle', value: 'weeklyId' }"
                @change="handleWeekChange" />
        </div>
        <div class="list">
            <div class="li" v-for="item in modularList" :key="item">
                <div class="content" :style="{ backgroundImage: `url(${item.img})` }" @click="onPlate(item)">
                    <h1>{{ item.tagName }}</h1>
                    <h2>{{ item.tagAlias }}</h2>
                    <h3 v-html="item.description"></h3>
                    <div class="btn">了解详情</div>
                </div>
                <div class="keyword_list">
                    <div class="keyword" v-for="k in item.keywords" :key="k.keyword"
                        @click="onDetails({ ...k, tagId: item.tagId })">{{ k.keyword }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import ReportHeader from '@/components/customer-week-report/phone/ReportHeader.vue';
// import HeaderRightLogo from '@/components/customer-week-report/phone/HeaderRightLogo.vue';
import PageTitleHome from '@/components/customer-week-report/phone/PageTitleHome.vue';
import WeekSelect from '@/components/customer-week-report/phone/WeekSelect.vue';
import ShowYear from '@/components/customer-week-report/phone/ShowYear.vue';
import ShowMonth from '@/components/customer-week-report/phone/ShowMonth.vue';

const emits = defineEmits(['update:weeklyId', 'toPlate', 'toDetails'])
defineProps({
    weekList: { type: Array, default: [] },
    weeklyId: { type: String, default: '' },
    modularList: { type: Array, default: [] },
    weekYearMonth: { type: Object, default: {} },
})

// 周报id切换
function handleWeekChange(e) {
    emits('update:weeklyId', e);
}

// 点击板块
function onPlate(e) {
    emits('toPlate', e);
}

// 点击关键词-跳转文章
function onDetails(e) {
    emits('toDetails', e);
}
</script>

<style lang="scss" scoped>
.container {
    min-height: 100vh;
    background-color: #f5f5f5;

    .choose_date {
        width: 215px;
        border-top: 1px solid var(--week-primary-color);
        position: absolute;
        top: 60px;
        right: 14px;
        padding-top: 10px;
        padding-left: 80px;
        display: flex;
        align-items: center;
    }

    .small_logo {
        width: 30px;
        position: absolute;
        top: 66px;
        right: 17px;

        img {
            max-width: 100%;
        }
    }

    .choose_month {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        padding-bottom: 3px;
    }

    .title_row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 17px;
        padding-top: 27px;
    }

    .list {
        padding: 30px 17px;

        .li {
            width: 100%;
            border-bottom: 1px solid #CDCDCD;
            position: relative;
            margin-bottom: 32px;

            &:last-child {
                margin-bottom: 0;
            }

            &::after {
                display: block;
                content: '';
                width: 100%;
                height: 1px;
                background-color: #fff;
                position: absolute;
                left: 0;
                bottom: -2px;
            }

            .content {
                width: inherit;
                height: 138px;
                background-color: #fff;
                box-shadow: -1.6px 7.8px 8.19px 0.81px rgba(0, 0, 0, 0.21);
                // background-image: url(~@/assets/img/customer-week-report/plate_bg.jpg);
                background-position: right bottom;
                background-repeat: no-repeat;
                background-size: auto 100%;
                position: relative;
                padding: 0 14px;
                padding-top: 19px;

                h1 {
                    font-size: 24px;
                    line-height: 1em;
                    text-shadow: 0 0 10px #fff;
                }

                h2 {
                    font-size: 12px;
                    line-height: 1em;
                    margin-top: 8px;
                    color: var(--week-primary-color);
                    font-weight: bold;
                }

                h3 {
                    max-width: 55%;
                    font-size: 12px;
                    line-height: 1.2em;
                    margin-top: 8px;
                    color: #2F2E2E;
                    font-weight: bold;
                    text-shadow: 0 0 10px #fff;
                }

                .btn {
                    line-height: 22px;
                    width: 90px;
                    text-align: center;
                    border-radius: 11px;
                    background-color: var(--week-primary-color);
                    color: #fff;
                    font-size: 10px;
                    font-weight: bold;
                    cursor: pointer;
                    position: absolute;
                    left: 14px;
                    bottom: 11px;
                }
            }

            .keyword_list {
                padding-top: 25px;
                padding-bottom: 16px;

                .keyword {
                    position: relative;
                    padding-left: 30px;
                    line-height: 1em;
                    font-size: 12px;
                    color: #727272;
                    margin-bottom: 12px;
                    cursor: pointer;

                    &:hover {
                        color: var(--week-primary-color);
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &::before {
                        display: block;
                        content: '';
                        width: 7px;
                        height: 7px;
                        background-color: var(--week-primary-color);
                        position: absolute;
                        left: 0;
                        top: 3px;
                    }
                }
            }
        }
    }

    .no_more {
        text-align: center;
        font-size: 12px;
        color: #646464;
        padding: 20px 0;
    }
}
</style>
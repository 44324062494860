<template>
    <div class="container">
        <ReportHeader>
            <div class="show_date">
                <ShowYear :year="weekYearMonth.year" />
                <ShowMonth :months="[weekYearMonth.month]" style="margin-left: 57px;" />
            </div>
            <div class="small_logo">
                <img src="@/assets/img/customer-week-report/cmj.png" alt="">
            </div>
        </ReportHeader>
        <BgContainer>
            <main>
                <div class="title_row">
                    <PageTitleHome title="新闻版块" />
                    <el-cascader class="choose_week_cascader" popper-class="choose_week_cascader_popper" placeholder="全部"
                        :model-value="weekCascaderValue" :options="weekCascaderList" :show-all-levels="false"
                        @change="handleWeekChange" />
                    <!-- <WeekSelect :list="weekList" :model-value="weeklyId"
                        :options="{ label: 'weeklyTitle', value: 'weeklyId' }" @change="handleWeekChange" /> -->
                </div>
                <div class="list">
                    <div class="li" v-for="item in modularList" :key="item">
                        <div class="content" :style="{ backgroundImage: `url(${item.img})` }">
                            <h1>{{ item.tagName }}</h1>
                            <h2>{{ item.tagAlias }}</h2>
                            <h3 v-html="item.description"></h3>
                            <div class="btn" @click="onPlate(item)">了解详情</div>
                        </div>
                        <div class="keyword_list">
                            <div class="keyword" v-for="k in item.keywords" :key="k.keyword"
                                @click="onDetails({ ...k, tagId: item.tagId })">{{ k.keyword }}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </BgContainer>
    </div>
</template>                           

<script setup>
import { computed } from 'vue';
import BgContainer from '@/components/customer-week-report/pc/BgContainer.vue';
import ReportHeader from '@/components/customer-week-report/pc/ReportHeader.vue';
import PageTitleHome from '@/components/customer-week-report/pc/PageTitleHome.vue';
import ShowYear from '@/components/customer-week-report/pc/ShowYear.vue';
import ShowMonth from '@/components/customer-week-report/pc/ShowMonth.vue';
import { cloneDeep } from 'loadsh';
import { groupBy } from '@/utils';
import { getMmDd } from '@/utils/date';

const emits = defineEmits(['update:weeklyId', 'toPlate', 'toDetails'])
const props = defineProps({
    weekList: { type: Array, default: [] },
    weeklyId: { type: String, default: '' },
    modularList: { type: Array, default: [] },
    weekYearMonth: { type: Object, default: {} },
})
// 按年/月/周报级联分组的周报列表
const weekCascaderList = computed(() => {
    const list = cloneDeep(props.weekList).map(item => {
        item['year'] = item.startTime?.split('-')[0];
        item['month'] = item.startTime?.split('-')[1];
        item['value'] = item.weeklyId;
        item['label'] = `${item.weeklyTitle}（${getMmDd(item?.startTime)}-${getMmDd(item?.endTime)}）`;
        return item;
    });
    // 按年份分组
    const groupObj = groupBy(list, 'year');
    for (let key in groupObj) {
        // 按月份分组
        groupObj[key] = groupBy(groupObj[key], 'month');
    }
    // 年份转为数组
    function obj2Arr(obj) {
        let result = [];
        for (let key in obj) {
            result.push({
                label: key + '月',
                value: key,
                children: obj[key]
            });
        }
        return result;
    }
    let res = [];
    for (let key in groupObj) {
        res.push({
            label: key + '年',
            value: key,
            children: obj2Arr(groupObj[key])
        });
    }
    return res;
})
// 周报选择框回显值
const weekCascaderValue = computed(() => {
    return [props.weekYearMonth.year, props.weekYearMonth.month, props.weeklyId]
})

// 周报选择框change
function handleWeekChange(e = []) {
    emits('update:weeklyId', e[e.length - 1]);
}

// 点击板块
function onPlate(e) {
    emits('toPlate', e);
}

// 点击关键词-跳转文章
function onDetails(e) {
    emits('toDetails', e);
}
</script>

<style lang="scss" scoped>
.container {
    background-color: #E6E6E6;
    min-height: 100vh;

    .show_date {
        border-top: 1px solid var(--week-primary-color);
        position: absolute;
        top: 60px;
        right: 44px;
        padding-top: 30px;
        padding-right: 100px;
        padding-left: 10px;
        display: flex;
        align-items: center;
    }

    .small_logo {
        position: absolute;
        top: 87px;
        right: 52px;
    }

    main {
        width: 100%;
        min-height: calc(100vh - var(--week-pc-header-height));
        background-color: #f5f5f5;
        box-shadow: 0 0 20px #ccc;
        padding: 46px 80px;

        .title_row {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .list {
            display: grid;
            grid-template-columns: repeat(2, auto);
            row-gap: 32px;
            justify-content: space-between;
            padding: 40px 0;

            .li {
                width: 413px;
                border-bottom: 1px solid #CDCDCD;
                position: relative;

                &::after {
                    display: block;
                    content: '';
                    width: 100%;
                    height: 1px;
                    background-color: #fff;
                    position: absolute;
                    left: 0;
                    bottom: -2px;
                }

                .content {
                    width: inherit;
                    height: 171px;
                    background-color: #fff;
                    box-shadow: -1.6px 7.8px 8.19px 0.81px rgba(0, 0, 0, 0.21);
                    // background-image: url(~@/assets/img/customer-week-report/plate_bg.jpg);
                    background-position: right bottom;
                    background-repeat: no-repeat;
                    background-size: auto 100%;
                    position: relative;
                    padding: 0 16px;
                    padding-top: 24px;

                    h1 {
                        font-size: 29px;
                        line-height: 1em;
                        text-shadow: 0 0 10px #fff;
                    }

                    h2 {
                        font-size: 16px;
                        line-height: 1em;
                        margin-top: 8px;
                        color: var(--week-primary-color);
                        font-weight: bold;
                    }

                    h3 {
                        max-width: 55%;
                        font-size: 14px;
                        line-height: 1.2em;
                        margin-top: 8px;
                        color: #2F2E2E;
                        font-weight: bold;
                        text-shadow: 0 0 10px #fff;
                    }

                    .btn {
                        line-height: 28px;
                        width: 100px;
                        text-align: center;
                        border-radius: 14px;
                        background-color: var(--week-primary-color);
                        color: #fff;
                        font-size: 14px;
                        font-weight: bold;
                        cursor: pointer;
                        position: absolute;
                        left: 16px;
                        bottom: 14px;
                    }
                }

                .keyword_list {
                    padding-top: 32px;
                    padding-bottom: 21px;

                    .keyword {
                        position: relative;
                        padding-left: 30px;
                        line-height: 1.2em;
                        font-size: 14px;
                        color: #727272;
                        margin-bottom: 12px;
                        cursor: pointer;

                        &:hover {
                            color: var(--week-primary-color);
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }

                        &::before {
                            display: block;
                            content: '';
                            width: 7px;
                            height: 7px;
                            background-color: var(--week-primary-color);
                            position: absolute;
                            left: 0;
                            top: 5px;
                        }
                    }
                }
            }
        }
    }
}
</style>

<style lang="scss">
.choose_week_cascader {
    width: 220px;

    .el-input__inner {
        border-color: #B7B7B7 !important;
        border-radius: 0;
        background: none;

        &:focus {
            border-color: #B7B7B7 !important;
        }
    }

    .el-input__suffix {
        background-color: var(--week-primary-color);
        right: 0;
        width: 32px;

        .el-input__suffix-inner {
            width: 100%;

            .el-input__icon {
                width: 100%;
                color: #fff;
            }
        }
    }
}

.choose_week_cascader_popper {

    .el-cascader-node.in-active-path,
    .el-cascader-node.is-active {
        color: var(--week-primary-color);
    }
}
</style>
<template>
    <el-popover placement="bottom-start" :width="216" trigger="click" popper-class="week_choose_popover"
        v-model:visible="visible" @after-enter="handleAfterEnter">
        <template #reference>
            <div class="week_choose">
                <div class="val">
                    <span class="textOverflowOne">{{ current?.[options.label] || '' }}
                        <template v-if="current?.[options.label] !== '全部'">
                            ({{ getMmDd(current?.startTime) }}-{{ getMmDd(current?.endTime) }})
                        </template>
                    </span>
                </div>
                <div class="icon_box">
                    <CaretBottom class="icon" :class="{ active: visible }" />
                </div>
            </div>
        </template>
        <template #default>
            <ul class="choose_year_list scrollbar">
                <li v-for="item in list" :key="item[options.value]" @click="handleClick(item)"
                    :class="{ active: item[options.value] === modelValue }">
                    {{ item[options.label] }}
                    <template v-if="item[options.label] !== '全部'">
                        ({{ getMmDd(item?.startTime) }}-{{ getMmDd(item?.endTime) }})
                    </template>
                </li>
            </ul>
        </template>
    </el-popover>
</template>

<script setup>
import { computed, ref } from 'vue';
import { CaretBottom } from '@element-plus/icons-vue';
import { getMmDd } from '@/utils/date';

const props = defineProps({
    list: { type: Array, default: [] },
    modelValue: { type: [Number, String], default: '' },
    options: { type: Object, default: { label: 'label', value: 'value' } }
})
const emits = defineEmits(['update:modelValue', 'change']);
const visible = ref(false);
// const viewVal = computed(() => {
//     return props.list.find(item => item[props.options.value] === props.modelValue)?.[props.options.label];
// })
const current = computed(() => {
    return props.list.find(item => item[props.options.value] === props.modelValue);
})

// 选择
function handleClick(item) {
    visible.value = false;
    emits('update:modelValue', item[props.options.value]);
    emits('change', item[props.options.value]);
}

// 打开
function handleAfterEnter() {
    // 打开后定位到当前选中位置
    const index = props.list.findIndex(item => item === props.modelValue);
    if (index === -1) return;
    const lis = document.querySelectorAll('.choose_year_list li');
    const dom = lis[index];
    dom.scrollIntoView({ behavior: 'instant', block: 'center' });
}
</script>

<style lang="scss" scoped>
.week_choose {
    --h: 28px;
    cursor: pointer;
    display: flex;

    .val {
        min-width: 120px;
        height: var(--h);
        border: 1px solid #B7B7B7;
        background-color: #FEFEFE;
        padding: 0 12px;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        border-right: none;

        span {
            display: flex;
            align-items: center;
            height: 100%;
            color: #2F2E2E;
            font-size: 14px;
            max-width: 240px;
        }
    }

    .icon_box {
        background-color: var(--week-primary-color);
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        width: var(--h);
        height: var(--h);
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
            width: 1em;
            height: 1em;
            color: #fff;
            transition: .3s;

            &.active {
                transform: rotate(180deg);
            }
        }
    }
}

.choose_year_list {
    min-height: 50px;
    max-height: 300px;
    overflow-y: auto;

    li {
        height: 38px;
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 14px;
        padding: 0 12px;

        &:hover {
            background-color: #f2f2f2;
        }

        &.active {
            background-color: #f2f2f2;
            font-weight: bold;
            color: var(--week-primary-color);
        }
    }
}
</style>

<style lang="scss">
.el-popover.el-popper.week_choose_popover {
    padding: 0;
}
</style>
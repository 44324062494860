/**
 * 获取月份
 * @param {string} str 日期字符串
 * @returns 
 */
export function getMonth(str) {
    if (!str || typeof str !== 'string') return str;
    str = str.replace(/\-/g, '/');
    return String(new Date(str).getMonth() + 1).padStart(2, '0');
}

/**
 * 获取月份和日期
 * @param {string} str 日期字符串
 * @param {string} mark 分割符号
 * @returns 
 */
export function getMmDd(str, mark = ".") {
    if (!str || typeof str !== 'string') return str;
    str = str.replace(/\-/g, '/')
    const date = new Date(str);
    return date.getMonth() + 1 + mark + date.getDate();
}

/**
 * 获取年和月
 * @param {string} str 日期字符串
 * @param {string} mark 分割符号
 * @returns 
 */
export function getYyMm(str, mark = ".") {
    if (!str || typeof str !== 'string') return str;
    str = str.replace(/\-/g, '/')
    const date = new Date(str);
    return date.getFullYear() + mark + (date.getMonth() + 1);
}

/**
 * 获取某一个月的最后一天
 */
export function getMonthLastDate(y, m) {
    const date = new Date();
    date.setFullYear(y);
    date.setMonth(m);
    date.setDate(0);
    return date.getDate();
}